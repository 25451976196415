<template>
  <div class="modal-content">
    <button type="button" class="close" @click="$emit('closeModal')">
      <span aria-hidden="true"
        ><svg v-svg symbol="x" size="0 0 17 17" role="presentation"
      /></span>
    </button>
    <div class="modal-body">
      <div class="title-h2 pb-2">{{ $t("support.modal_title") }}</div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-item pb-2">
            <div class="form-item__label">{{ $t("support.modal.topic") }}</div>
            <div class="form-item__field">
              <input
                v-model="name"
                type="text"
                :class="{ invalid: $v.name.$error }"
              />
              <small
                v-if="$v.name.$error && (!$v.name.minLength || name === '')"
                class="error"
                >{{ $t("min_len_pass", { number: "3" }) }}</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-item pb-2">
            <div class="form-item__label">
              {{ $t("support.modal.question_topic") }}
            </div>
            <div class="form-item__field">
              <custom-multiselect
                v-model="selectedLanding"
                :list="selectLanding"
                :allowEmpty="false"
              >
                <template v-slot:block="props">
                  <span>
                    {{ props.value.title }}
                  </span>
                </template>
                <template v-slot:list="props">
                  <span>
                    {{ props.value.title }}
                  </span>
                </template>
              </custom-multiselect>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-item pb-2">
            <div class="form-item__label">
              {{ $t("support.modal.message") }}
            </div>
            <div class="form-item__field">
              <textarea
                name="text"
                rows="4"
                v-model="message"
                :class="{ invalid: $v.message.$error }"
              ></textarea>
              <small
                v-if="
                  $v.message.$error && (!$v.message.minLength || message === '')
                "
                class="error"
                >{{ $t("min_len_pass", { number: "3" }) }}</small
              >
            </div>
          </div>
        </div>
        <div class="col-12">
          <button @click="createTicket" type="submit" class="btn _mob100">
            {{ $t("support.modal.submit") }}
          </button>
        </div>
      </div>
    </div>
    <alert v-if="$alert.title" />
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import SupportService from "@/api/supportService";
import "@/assets/css/_tubs.scss";
import "@/assets/css/_code.scss";
import "@/assets/css/_modal.scss";

export default {
  name: "CreateTicketModal",
  props: {
    closeModal: {
      type: Function,
      default: () => {},
    },
    createdTicket: {
      type: Function,
      default: () => {},
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      name: "",
      message: "",
      selectedLanding: {
        title: this.$t("support.modal.modal_select.main_questions"),
        value: 0,
      },
      selectLanding: [
        {
          title: this.$t("support.modal.modal_select.main_questions"),
          value: 0,
        },
        {
          title: this.$t("support.modal.modal_select.technical_questions"),
          value: 1,
        },
        {
          title: this.$t("support.modal.modal_select.financial_questions"),
          value: 2,
        },
      ],
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(126),
    },
    message: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(2000),
    },
  },
  watch: {
    name: function (newVal, oldVal) {
      if (newVal.length > 126) {
        this.name = oldVal;
      }
    },
    message: function (newVal, oldVal) {
      if (newVal.length > 2000) {
        this.message = oldVal;
      }
    },
  },
  methods: {
    createTicket() {
      this.$v.$touch();
      let formData = new FormData();
      formData.append("subject", this.name);
      formData.append("message", this.message);
      formData.append("type", this.selectedLanding.value);

      if (this.$v.$invalid) {
        const invalidField = this.$v.name.$invalid
          ? this.$t("support.modal.topic")
          : this.$t("support.modal.message");
        this.$alert({
          title: this.$t("sent_error"),
          text: `${this.$t("fields_invalid")}: ${invalidField} <br> ${this.$t(
            "min_length"
          )} 3`,
        });
        return;
      } else {
        SupportService.createTicket(formData).then((res) => {
          if (res) {
            if (res.status === 200) {
              this.$alert({
                title: this.$t("sent_success"),
                text: res.message,
                type: "success",
              });
              this.$emit("createdTicket", res.data.id);
              this.$emit("closeModal");
            } else {
              this.$alert({
                title: this.$t("sent_error"),
                text: res.message,
              });
            }
          }
        });
      }
    },
  },
};
</script>
