<template>
  <div class="no-information">
    <span class="icon-support"></span>
    <h3>{{ $t("support.no_ticket") }}</h3>
    <p>{{ $t("support.need_help") }}</p>
  </div>
</template>
<script>
import "@/assets/css/_no-information.scss";

export default {
  name: "noInfromation",
};
</script>
