<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-auto">
        <h1>{{ $t("support.title") }}</h1>
      </div>
      <div class="col-12 col-auto">
        <button class="btn" @click="openLinkModal">
          + {{ $t("support.create_ticket") }}
        </button>
      </div>
    </div>
    <div class="sep _h10"></div>
    <div class="c-table mt-5 mt-md-0">
      <div class="inner" v-if="rows.length && !loadDataTable">
        <tableAnimation />
        <vue-good-table
          styleClass="table vgt-table sortEnable tablesorter tablesorter-bootstrap"
          :columns="columns"
          :rows="rows"
        >
          <template slot="table-column" slot-scope="props">
            <span class="cursor-pointer">
              {{ formatLabel(props.column.label) }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <template v-if="props.column.field === 'name'">
              <router-link
                exact
                :to="`/support-chat/${props.row.id}`"
                active-class="active"
              >
                <span class="font-weight-bold">{{ props.row.name }}</span>
              </router-link>
            </template>
            <template v-if="props.column.field === 'type'">
              {{ $t("support.tech_support") }}
            </template>
            <template v-if="props.column.field === 'createdAt'">
              {{ props.row.date }}
            </template>
            <template v-if="props.column.field === 'status'">
              <button
                v-if="!props.row.status"
                @click="setStatus(props.row.id, 1)"
                class="link"
              >
                <svg
                  v-svg
                  symbol="lock-open"
                  size="0 1 15 18"
                  role="presentation"
                />
                {{ $t("support.close_ticket") }}
              </button>
              <button
                v-else
                @click="setStatus(props.row.id, 0)"
                class="link disabled"
              >
                <svg v-svg symbol="lock" size="0 0 15 15" role="presentation" />
                {{ $t("support.closed") }}
              </button>
            </template>
          </template>
          <div slot="emptystate">
            {{ $t("any_results") }}
          </div>
        </vue-good-table>
      </div>
      <div v-else class="empty">
        <NoInformation />
      </div>
    </div>
    <modal
      :width="780"
      name="createTicketModal"
      height="auto"
      :scrollable="true"
      classes="creative-modal"
      :shiftY="0"
    >
      <CreateTicketModal
        @createdTicket="createdTicket"
        @closeModal="closeModal"
      />
    </modal>
  </div>
</template>

<script>
import "@/assets/css/_page-support.scss";
import TableAnimation from "@/components/TableAnimation";
import NoInformation from "@/components/NoInformation.vue";
import CreateTicketModal from "@/components/CreateTicketModal.vue";
import SupportService from "@/api/supportService";

export default {
  name: "Support",
  beforeMount() {
    document.title = `Partners.House | ${this.$t("support.title")}`;
  },
  beforeUpdate() {
    document.title = `Partners.House | ${this.$t("support.title")}`;
  },
  components: { TableAnimation, NoInformation, CreateTicketModal },
  data() {
    return {
      loadDataTable: false,
      columns: [
        {
          label: "support.table.name",
          field: "name",
        },
        {
          label: "support.table.department",
          field: "type",
          width: "18%",
          sortable: false,
        },
        {
          label: "support.table.date",
          field: "createdAt",
          type: "date",
          dateInputFormat: "MM-dd-yyyy",
          dateOutputFormat: "MM-dd-yyyy",
          width: "15%",
        },
        {
          label: "",
          field: "status",
          sortable: false,
          width: "20%",
        },
      ],
      rows: [],
    };
  },
  created() {
    this.getUserTickets();
  },
  methods: {
    formatLabel(el) {
      return this.$t(el);
    },
    setStatus(id, status) {
      const payload = {
        id,
        status,
      };
      SupportService.setTicketStatus(payload).then((res) => {
        const field = this.rows.find((res) => res.id === id);
        if (res && res.status === 200) {
          field.status = status;
        }
      });
    },
    getUserTickets() {
      this.loadDataTable = true;
      SupportService.getTickets()
        .then((res) => {
          if (res && res.status === 200) {
            this.rows = res.data;
          }
        })
        .finally(() => {
          this.loadDataTable = false;
        });
    },
    createdTicket(id) {
      this.$router.push(`/support-chat/${id}`);
    },

    closeModal() {
      this.$modal.hide("createTicketModal");
    },
    openLinkModal() {
      this.$modal.show("createTicketModal");
    },
  },
};
</script>
<style scoped lang="scss">
.link {
  display: flex;
  background-color: transparent;
  line-height: 1;
}

.link:focus {
  outline: none;
}

.link.disabled {
  svg {
    fill: #919191;
  }
}

.c-table {
  &::v-deep {
    .table {
      thead th,
      tbody td {
        text-align: left;
      }

      thead th.sortable {
        ::before,
        ::after {
          display: none;
        }
      }
    }
  }
}
</style>
